import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleComponent } from './role/role.component';
import { InputTextModule } from 'primeng/inputtext';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';

@NgModule({
	declarations: [RoleComponent],
	imports: [
		CommonModule,
		RippleModule,
	],
	exports: [
		RoleComponent,
		InputTextModule,
		AutoFocusModule,
		ButtonModule,
		ToastModule
	]
})
export class AppCommonModule { }
