import { ChangeDetectorRef, Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { TypedDocumentNode } from 'apollo-angular';
import _, { forEach } from 'lodash';
import { Subscription } from 'rxjs';
import { XOR } from 'ts-xor';

/**
 * Reasons why you should use this service to manage state in your components:
 * ---------------------------------------------------------------------------

 * 1. You have a list of items that are meant to be editable, and they all have view and edit states. For example, a list of roles where each role has a view state and an edit state.
 *
 * 2. You want to leverage methods that are useful in a list, such as isEditableIfOnlyOneEditableIsAllowed(), isCreatableIfOnlyOneCreatableIsAllowed(), and isGenericEditableIfOnlyOneGenericEditableIsAllowed(), which are useful for determining which items in a list should have edit capabilities, and which items should not.
 *
 * 3. Even if you are dealing with a single item rather than a list, and you want to leverage the state transition functions such as create(), edit(), view(), instead of writing your own in the component. This helps keep the entire app consistent in terms of how state transitions are handled for the typical types of items that have view and edit states. This also applies to the State enum, which is useful for keeping the entire app consistent in terms of the names of the states.
 *
 * 4. You have a list of items that are meant to be editable in addition to intermingled items in between that are meant for creation. For example, a list of flags that are editable, and a final row in the list that is meant for creating a new flag. In this case, your component template and controller should call the generic* functions, and the CrudStateService will handle the differences between the create and edit states such that the component template and controller don't have to be too concerned about whether you are creating a flag or editing a flag. This helps keep your component template and controller free of excessive if/else statements.
 *
 *		For example, using the generic functions and generic models (that each internally point to either an editable model or a creatable model), you can do something like this in your component template:
 *			<div class="input-containers" *ngIf="flagNodeCrud.genericIsInCreateOrEditState(nodeKey)">
 *				<input
 *					[formControl]="flagNodeCrud.creatableOrEditable(nodeKey).controls.key"
 *					...
 *				/>
 *			</div>
 *
 * 		Notice how in this generic case, you don't have any if/else statements depending on whether you're creating a flag or editing a flag, and so you don't have to risk repeating the same code twice and risk potentially having a discrepancy between the two.
 *
 * 5. Even if you are dealing with a single item rather than a list, and you want to leverage the generic functions such as genericCreateOrEdit(), genericView(), and genericDelete(), so you don't have to repeat the same code twice for creating and editing.
 *
 *
 *
 * Reasons why you should NOT use this service to manage state in your components:
 * -------------------------------------------------------------------------------
 *
 * 1. Your states are more complex than simply view or edit. For example, if you are modifying a scope snapshot's inheritances, you have states such as VIEW, LOADING_MODIFICATIONS, MODIFY, RELOADING_MODIFICATIONS, SAVING, and SAVED at the section level (you can check out the ScopeSnapshotInheritancesComponent for more details). And at the individual inheritance row level, you have states such as INHERITED_AS_DIRECT_PARENT, INHERITED, CONFLICTING_INHERITANCES, and NOT_INHERITED. Trying to use this service to manage the states of the modify inheritances section would just make things more complicated and cumbersome.
 *
 * 2. Following from the previous point, if transitioning from the view state to the edit state involves more than just a transformation function (ie, if it involves a gql query), then this service will probably be too cumbersome to use. Unless you want to rewrite a portion of this service to handle that case.
 *
 * 3. Your creatable or editable model is too complex to be represented using FormGroups, FormControls, and FormArrays. For example, in the modify scope snapshot inheritances section, we have a list of scope snapshot selectors. Trying to represent each scope snapshot selector using FormGroups, FormControls, and FormArrays would at the very least be a big change, if not cumbersome, and that is why we don't use this service for the scope snapshot selector.
 *
 **/

export enum State {
	VIEW = "VIEW",
	CREATE_OR_EDIT = "CREATE_OR_EDIT",
	DELETE_CONFIRMATION = "DELETE_CONFIRMATION",
}

export enum ModelType {
	EDITABLE = "EDITABLE",
	CREATABLE = "CREATABLE",
}

export type CrudStateServiceClass<
		EditableModelKey extends {id: ID},
		EditableModel extends FormGroup,
		CreatableModelKey extends {},
		CreatableModel extends FormGroup
	> = (

		new (
			modelToEditableModelTransformer: ((_: EditableModelKey) => EditableModel),
			keyToCreatableModelTransformer: ((_: CreatableModelKey) => CreatableModel)
		) =>

		CrudStateService<EditableModelKey, EditableModel, CreatableModelKey, CreatableModel>
);

export type ID = string | number;
// export type DefaultEditableModel<T extends {id: ID}> = FormGroup<{
// 	[key in keyof Omit<T, "__typename">]: any
// }>;

export type GenericizedModelKey<EditableModelKey, CreatableModelKey> = {
	type: ModelType.EDITABLE,
	editableModelKey: EditableModelKey,
} | {
	type: ModelType.CREATABLE,
	creatableModelKey: CreatableModelKey
};

export type CrudTypes<EditableModelKey extends {id: ID}, EditableModel extends FormGroup, CreatableModelKey extends {} = {}, CreatableModel extends FormGroup = FormGroup> = {
	transformers: {
		keyToCreatable: ((key: CreatableModelKey) => CreatableModel),
		modelToEditable: ((model: EditableModelKey) => EditableModel),
	},
	crudClass: CrudStateServiceClass<EditableModelKey, EditableModel, CreatableModelKey, CreatableModel>,
	crud: CrudStateService<EditableModelKey, EditableModel, CreatableModelKey, CreatableModel>,
	injectionTokens: {
		keyToCreatableModelTransformer: InjectionToken<(_: CreatableModelKey) => CreatableModel>,
		modelToEditableModelTransformer: InjectionToken<(_: EditableModelKey) => EditableModel>,
	},
	genericizedModelKey: GenericizedModelKey<EditableModelKey, CreatableModelKey>,
	genericizedStateModel: GenericizedStateModel<EditableModel, CreatableModel>,
}

type StateModel<M> = {
	model: M,
	state: State
};

export type EditableStateModel<M> = StateModel<M> & {
	type: ModelType.EDITABLE,
	isMarkedForDeletion: boolean,
};

export type CreatableStateModel<M> = StateModel<M> & {
	type: ModelType.CREATABLE,
};

export type GenericizedStateModel<EditableModel, CreatableModel> = EditableStateModel<EditableModel> | CreatableStateModel<CreatableModel>;

export type GqlRequestInfo<T, V> = {
	subscription: Subscription | null,
	gql: TypedDocumentNode<T, V>,
};

export function gqlRequestInfo<T, V>(gql: TypedDocumentNode<T, V>): GqlRequestInfo<T, V> {
	return {
		gql,
		subscription: null,
	};
};

export type GqlFragmentInfo<T> = TypedDocumentNode<T>;

// To be used internally by CrudStateService only, for the purposes of the unsubscription helper function
// Do not export this type because the types of GqlRequestInfo in here are too generic (ie, <any, any>)
type GqlRequestInfos = {
	queries?: {[key: string]: GqlRequestInfo<any, any>},
	mutations?: {[key: string]: GqlRequestInfo<any, any>},
	fragments?: {[key: string]: GqlFragmentInfo<any>}
};

@Injectable({
  providedIn: 'root'
})
export class CrudStateService<EditableModelKey extends XOR<{id: string | number}, {_crudId: string | number}>, EditableModel extends FormGroup, CreatableModelKey extends {} = {}, CreatableModel extends FormGroup = FormGroup> {

	private editableStateModels:	Map<string, EditableStateModel<EditableModel>>		= new Map();
	private creatableStateModels:	Map<string,	CreatableStateModel<CreatableModel>>	= new Map();

  constructor(
		@Inject("ModelToEditableModelTransformer") @Optional() public modelToEditableModelTransformer?: (model: EditableModelKey) => EditableModel,
		@Inject("KeyToCreatableModelTransformer") @Optional() public keyToCreatableModelTransformer?: (model: CreatableModelKey) => CreatableModel,
		@Inject("CrudOptions") @Optional() public options?: {
			ignoreFieldsForDirtyCheck?: (keyof EditableModel["controls"])[],
		}
	) { }

	private stringifiedMapKeyForEditable(key: EditableModelKey): string {
		let crudIdentifier: string | number;
		if (key.id !== undefined) {
			crudIdentifier = key.id;
		}
		else {
			crudIdentifier = key._crudId;
		}
		return JSON.stringify(crudIdentifier);
	}

	private stringifiedMapKeyForCreatable(key: CreatableModelKey): string {
		// Taken from https://github.com/lodash/lodash/issues/1459#issuecomment-253969771.
		// Sort the object's keys so that the resulting stringified keys are the same for objects that have the same property/value pairs.
		// ie, the CreatableModelKeys {scopeVariableId: 1, index: 2} and {index: 2, scopeVariableId: 1} should be considered equal.

		// TODO This becomes inefficient when there are ~5000 flags.
		const sortedAndComparableKey = _(key).toPairs().sortBy(0).fromPairs().value();

		return JSON.stringify(sortedAndComparableKey);
	}

	// This can probably return a union type of EditableModelKey | CreatableModelKey, if we need to later.
	private objectifiedMapKey(stringifiedMapKey: string): CreatableModelKey {
		return JSON.parse(stringifiedMapKey);
	}

	private initializeCreatable(key: CreatableModelKey, reuseExistingModelIfExists: boolean = true, initialState: State = State.VIEW): CreatableStateModel<CreatableModel> {
		if (!this.keyToCreatableModelTransformer) {
			throw new Error(`No keyToCreatableModelTransformer exists.`);
		}

		if (!reuseExistingModelIfExists || !this.creatable(key)) {
			const creatableModel = this.keyToCreatableModelTransformer(key);
			const creatableStateModel = {
				type: ModelType.CREATABLE as const,
				model: creatableModel,
				state: initialState
			}
			this.creatableStateModels.set(this.stringifiedMapKeyForCreatable(key), creatableStateModel);

			return creatableStateModel;
		}
		else {
			const creatableStateModel = this.requireCreatable(key);
			creatableStateModel.state = initialState;
			return creatableStateModel;
		}
	}

	private initializeEditable(originalObj: EditableModelKey, reuseExistingModelIfExists: boolean = true, initialState: State = State.CREATE_OR_EDIT): EditableStateModel<EditableModel> {
		if (!this.modelToEditableModelTransformer) {
			throw new Error(`No modelToEditableModelTransformer exists.`);
		}

		if (!reuseExistingModelIfExists || !this.editable(originalObj)) {
			const key = this.stringifiedMapKeyForEditable(originalObj);
			const editableModel = this.modelToEditableModelTransformer(originalObj);
			const editableStateModel = {
				type: ModelType.EDITABLE as const,
				model: editableModel,
				state: initialState,
				isMarkedForDeletion: false
			}

			this.editableStateModels.set(key, editableStateModel);

			return editableStateModel;
		}
		else {
			const editableStateModel = this.requireEditable(originalObj);
			editableStateModel.state = initialState;
			return editableStateModel;
		}
	}

	requireCreatable(key: CreatableModelKey): CreatableStateModel<CreatableModel> {
		const stateModel = this.creatable(key);
		if (!stateModel) {
			throw new Error(`No creatable state model with key ${key} exists`);
		}

		return stateModel;
	}

	creatable(key: CreatableModelKey): CreatableStateModel<CreatableModel> | undefined {
		return this.creatableStateModels.get(this.stringifiedMapKeyForCreatable(key));
	}

	requireEditable(key: EditableModelKey): EditableStateModel<EditableModel> {
		const stateModel = this.editable(key);
		if (!stateModel) {
			throw new Error(`No editable state model with key ${this.stringifiedMapKeyForEditable(key)} exists`);
		}

		return stateModel;
	}

	editable(key: EditableModelKey): EditableStateModel<EditableModel> | undefined {
		return this.editableStateModels.get(this.stringifiedMapKeyForEditable(key));
	}

	creatableModelKeys(partialKey: Partial<CreatableModelKey>): CreatableModelKey[] {
		const creatableModelKeys: CreatableModelKey[] = [];

		this.creatableStateModels.forEach((_creatableStateModel, keyString) => {
			const key = this.objectifiedMapKey(keyString);
			_.isMatch(key, partialKey) && creatableModelKeys.push(key);
		});

		return creatableModelKeys;
	}

	creatables(partialKey: Partial<CreatableModelKey>): Map<CreatableModelKey, CreatableStateModel<CreatableModel>> {
		const creatableModelKeys = this.creatableModelKeys(partialKey);

		return new Map().set(creatableModelKeys, creatableModelKeys.map(creatableModelKey => this.creatable(creatableModelKey)));
	}

	creatableOrEditable(key: GenericizedModelKey<EditableModelKey, CreatableModelKey>): GenericizedStateModel<EditableModel, CreatableModel> | undefined {
		if (key.type === ModelType.EDITABLE) {
			return this.editable(key.editableModelKey);
		}
		else {
			return this.creatable(key.creatableModelKey);
		}
	}

	requireCreatableOrEditable(key: GenericizedModelKey<EditableModelKey, CreatableModelKey>): GenericizedStateModel<EditableModel, CreatableModel> {
		const creatableOrEditable = this.creatableOrEditable(key);
		if (!creatableOrEditable) {
			throw new Error(`No creatable or editable model with key ${key} exists`);
		}

		return creatableOrEditable;
	}

	create(key: CreatableModelKey, reuseExistingModelIfExists: boolean = false, initialState: State = State.CREATE_OR_EDIT): CreatableStateModel<CreatableModel> {
		return this.initializeCreatable(key, reuseExistingModelIfExists, initialState);
	}

	edit(key: EditableModelKey, reuseExistingModelIfExists: boolean = false, initialState: State = State.CREATE_OR_EDIT): EditableStateModel<EditableModel> {
		return this.initializeEditable(key, reuseExistingModelIfExists, initialState);
	}

	enterDeletePrompt(key: EditableModelKey, reuseExistingModelIfExists: boolean = true): EditableStateModel<EditableModel> {
		return this.initializeEditable(key, reuseExistingModelIfExists, State.DELETE_CONFIRMATION);
	}

	exitDeletePrompt(esmInDeletePromptState: EditableStateModel<EditableModel>, newState: State = State.CREATE_OR_EDIT): EditableStateModel<EditableModel> {
		esmInDeletePromptState.state = newState;
		return esmInDeletePromptState;
	}

	genericCreateOrEdit(genericKey: GenericizedModelKey<EditableModelKey, CreatableModelKey>, reuseExistingModelIfExists: boolean, initialState: State = State.CREATE_OR_EDIT): GenericizedStateModel<EditableModel, CreatableModel> {
		if (genericKey.type === ModelType.EDITABLE) {
			return this.edit(genericKey.editableModelKey, reuseExistingModelIfExists, initialState);
		}
		else {
			return this.create(genericKey.creatableModelKey, reuseExistingModelIfExists, initialState);
		}
	}

	view(key: EditableModelKey): EditableStateModel<EditableModel> {
		const stateModel = this.requireEditable(key);
		stateModel.state = State.VIEW;
		return stateModel;
	}

	viewCreatable(key: CreatableModelKey): CreatableStateModel<CreatableModel> {
		const stateModel = this.requireCreatable(key);
		stateModel.state = State.VIEW;
		return stateModel;
	}

	genericView(genericKey: GenericizedModelKey<EditableModelKey, CreatableModelKey>): GenericizedStateModel<EditableModel, CreatableModel> {
		if (genericKey.type === ModelType.EDITABLE) {
			return this.view(genericKey.editableModelKey)
		}
		else {
			return this.viewCreatable(genericKey.creatableModelKey)
		}
	}

	delete(key: EditableModelKey): EditableStateModel<EditableModel> {
		this.initializeEditable(key, true, State.VIEW);
		const stateModel = this.requireEditable(key);
		stateModel.isMarkedForDeletion = true;
		return stateModel;
	}

	genericDelete(genericKey: GenericizedModelKey<EditableModelKey, CreatableModelKey>): EditableStateModel<EditableModel> | boolean {
		if (genericKey.type === ModelType.EDITABLE) {
			return this.delete(genericKey.editableModelKey);
		}
		else {
			return this.discardCreatable(genericKey.creatableModelKey);
		}
	}

	creatableInCreateState(key: CreatableModelKey): CreatableStateModel<CreatableModel> | undefined {
		const stateModel = this.creatable(key);
		if (stateModel?.state === State.CREATE_OR_EDIT) {
			return stateModel;
		}
	}

	creatableInViewState(key: CreatableModelKey): CreatableStateModel<CreatableModel> | undefined {
		const stateModel = this.creatable(key);
		if (stateModel?.state === State.VIEW) {
			return stateModel;
		}
	}

	editableInEditState(key: EditableModelKey): EditableStateModel<EditableModel> | undefined {
		const stateModel = this.editable(key);
		if (stateModel?.state === State.CREATE_OR_EDIT) {
			return stateModel;
		}
	}

	editableInDeleteConfirmationState(key: EditableModelKey): EditableStateModel<EditableModel> | undefined {
		const stateModel = this.editable(key);
		if (stateModel?.state === State.DELETE_CONFIRMATION) {
			return stateModel;
		}
	}

	isEditableInViewState(key: EditableModelKey): boolean {
		const editable = this.editable(key);
		return !editable || editable?.state === State.VIEW;
	}

	// isCreatableInViewState(key: CreatableModelKey): boolean {
	// 	const creatable = this.creatable(key);
	// 	return !creatable || creatable?.state === State.VIEW;
	// }

	genericInCreateOrEditState(genericKey: GenericizedModelKey<EditableModelKey, CreatableModelKey>): GenericizedStateModel<EditableModel, CreatableModel> | undefined {
		const stateModel = this.creatableOrEditable(genericKey);

		if (stateModel?.state === State.CREATE_OR_EDIT) {
			return stateModel;
		}
	}

	genericIsInViewState(genericKey: GenericizedModelKey<EditableModelKey, CreatableModelKey>): boolean {
		const generic = this.creatableOrEditable(genericKey);
		return !generic || generic?.state === State.VIEW;
	}

	areAnyInCreateOrEditState(): boolean {
		return this.areAnyCreatablesInCreateState() || this.areAnyEditablesInEditState();
	}

	areAnyEditablesInEditState(): boolean {
		return Array.from(this.editableStateModels.values()).some(stateModel => stateModel.state === State.CREATE_OR_EDIT);
	}

	areAnyCreatablesInCreateState(): boolean {
		return Array.from(this.creatableStateModels.values()).some(stateModel => stateModel.state === State.CREATE_OR_EDIT);
	}

	isEditableIfOnlyOneEditableIsAllowed(key: EditableModelKey): boolean {
		return !this.areAnyEditablesInEditState() || this.editable(key)?.state === State.CREATE_OR_EDIT;
	}

	isCreatableIfOnlyOneCreatableIsAllowed(key: CreatableModelKey): boolean {
		return !this.areAnyCreatablesInCreateState() || this.creatable(key)?.state === State.CREATE_OR_EDIT;
	}

	isGenericEditableIfOnlyOneGenericEditableIsAllowed(genericKey: GenericizedModelKey<EditableModelKey, CreatableModelKey>): boolean {
		const areAnyGenericsEditable = this.areAnyCreatablesInCreateState() || this.areAnyEditablesInEditState();
		return !areAnyGenericsEditable || !!this.genericInCreateOrEditState(genericKey);
	}

	private isAbstractControlDirty(abstractControl: AbstractControl, nameOrIndex: string | null = null): boolean {
		if (abstractControl instanceof FormControl) {
			// Ignore the dirty check if the field is in the ignoreFieldsForDirtyCheck list
			// TODO This only works for top-level fields. We should make it work for nested fields as well if we ever need to. This will require a recursive function and also a recursive ignoreFieldsForDirtyCheck object.
			if (nameOrIndex !== null && this.options?.ignoreFieldsForDirtyCheck && abstractControl.parent && abstractControl.parent === abstractControl.root && this.options.ignoreFieldsForDirtyCheck.includes(nameOrIndex)) {
				return false;
			}

			// if (abstractControl.defaultValue !== abstractControl.value) {
			// 	console.log(`Abstract control ${abstractControl} is dirty because its defaultValue (${abstractControl.defaultValue}) is different from its value (${abstractControl.value})`);
			// }
			return abstractControl.defaultValue !== abstractControl.value;
		}
		else if (abstractControl instanceof FormGroup) {
			return Object.entries(abstractControl.controls).some(nameAndControl => this.isAbstractControlDirty(nameAndControl[1], nameAndControl[0]));
		}
		else if (abstractControl instanceof FormArray) {
			return Object.entries(abstractControl.controls).some(nameAndControl => this.isAbstractControlDirty(nameAndControl[1], nameAndControl[0]));
		}
		else {
			throw new Error(`Invalid abstract control ${abstractControl}. Cannot check if it is dirty.`);
		}
	}


	private isEditableModelDirty(editableModel: FormGroup): boolean {
		return this.isAbstractControlDirty(editableModel);
	}

	isDirty(key: EditableModelKey): boolean {
		const editable = this.editable(key);
		if (!editable) {
			return false;
		}

		if (!this.modelToEditableModelTransformer) {
			throw new Error(`No modelToEditableModelTransformer exists.`);
		}
		const editableModel = this.editable(key)?.model;
		if (!editableModel) {
			return false;
		}
		// const initialEditableModel = this.modelToEditableModelTransformer(key);

		if (this.isMarkedForDeletion(key)) {
			return true;
		}

		return this.isEditableModelDirty(editableModel);
	}

	genericIsDirty(genericKey: GenericizedModelKey<EditableModelKey, CreatableModelKey>): boolean {
		if (genericKey.type === ModelType.EDITABLE) {
			return this.isDirty(genericKey.editableModelKey);
		}
		else {
			return !!this.creatable(genericKey.creatableModelKey);
		}
	}

	isMarkedForDeletion(key: EditableModelKey): boolean {
		return this.editable(key)?.isMarkedForDeletion === true;
	}

	genericIsDeleted(genericKey: GenericizedModelKey<EditableModelKey, CreatableModelKey>): boolean {
		if (genericKey.type === ModelType.EDITABLE) {
			return this.isMarkedForDeletion(genericKey.editableModelKey);
		}
		return false;
	}

	discardCreatable(key: CreatableModelKey): boolean {
		return this.creatableStateModels.delete(this.stringifiedMapKeyForCreatable(key));
	}

	discardEditable(key: EditableModelKey): boolean {
		return this.editableStateModels.delete(this.stringifiedMapKeyForEditable(key));
	}

	discardGeneric(genericKey: GenericizedModelKey<EditableModelKey, CreatableModelKey>): boolean {
		let result = false;
		if (genericKey.type === ModelType.EDITABLE) {
			result = this.discardEditable(genericKey.editableModelKey);
		}
		else {
			result = this.discardCreatable(genericKey.creatableModelKey);
		}
		return result;
	}

	discardCreatables(): void {
		this.creatableStateModels.clear();
	}

	discardEditables(): void {
		this.editableStateModels.clear();
	}

	discardNonDeletedEditables(): void {
		for (const id of this.editableStateModels.keys()) {
			const stateModel = this.editableStateModels.get(id);
			if (stateModel && !stateModel.isMarkedForDeletion) {
				this.editableStateModels.delete(id);
			}
		}
	}

	discardAllModels(): void {
		this.discardCreatables();
		this.discardEditables();
	}

	// TODO We should move this function to a more appropriate place outside of CrudStateService
	static unsubscribeFromGqlSubscriptions(gqlRequestInfos: GqlRequestInfos): void {
		_.each({...gqlRequestInfos.queries, ...gqlRequestInfos.mutations}, request => request.subscription?.unsubscribe());
	}
}
