import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
	messageService: MessageService;
	auth: AuthService;
	clipboard: Clipboard;

  constructor(messageService: MessageService, auth: AuthService, clipboard: Clipboard) {
		this.messageService = messageService;
		this.auth = auth;
		this.clipboard = clipboard;
	}

	queryErrorHandler(error: any) {
		console.log('There was an error with the request:', error);
		this.messageService.add({severity: 'error', summary: 'Error', detail: error, life: 10000});
	}

	mutationErrorHandler(error: any) {
		console.log('There was an error saving:', error);
		this.messageService.add({severity: 'error', summary: 'Error', detail: error, life: 20000});
	}

	copyBearerTokenToClipboard() {
		this.auth.getAccessTokenSilently({cacheMode: 'cache-only'}).subscribe({
			next: (bearerToken: string) => {
				this.clipboard.copy(bearerToken);
				this.messageService.add({
					severity:'success',
					summary: 'Bearer token copied! Use this header format in the API to fetch your config:',
					detail: `Authorization: Bearer <BEARER TOKEN>`,
					life: 15000
				});
			}
		});
	}

	static friendlyVersionLabel(version: string): string {
		const startsWithNumber = /^\d/.test(version);
		// const startsWithVFollowedByNumber = /^(v|V)\d/.test(version);
		// const startsWithVersion = /^(v|V)ersion/.test(version);

		if (startsWithNumber) {
			return `v${version}`;
		}
		// else if (startsWithVFollowedByNumber) {
		// 	return version;
		// }
		// else if (startsWithVersion) {
		// 	return version;
		// }
		else {
			return `'${version}'`;
		}
	}
}
