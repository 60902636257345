import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-role',
	templateUrl: './role.component.html',
	styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

	@Input('role') role?: {name: string};
	@Input('is-edit-mode') isEditMode = false;
	@Output('remove-role') removeRoleEvent = new EventEmitter();

	constructor() { }

	ngOnInit(): void {
	}

}
